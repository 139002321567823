body {
  font-size: 0.5rem;
}
.brand-logo-mini {
  display: none !important;
}

.nav-item {
  margin-left: 2px !important;
  margin-right: 0px !important;
}

.ico-toggle {
  font-size: 15px;
}
.btn-toggle-group {
  min-width: 20px !important;
}
.mb-mobile-btn-toggle {
  margin-left: 3px !important;
  margin-right: 3px;
}
.title-language {
  font-size: $medium-size;
}

.flag-icon {
  width: 15px;
  height: 15px;
}

.user-avatar {
  img {
    width: 18px !important;
    height: 18px !important;
    margin-right: 20px !important;
  }
}
.msg-reset-pass {
  margin-top: 5px;
  margin-bottom: 20px;
}
.button,
.btn-sm {
  font-size: $min-size !important;
  letter-spacing: 0rem !important;
}

.dropdown-item {
  font-size: $min-size !important;
  padding: 5px !important;
}

.content-wrapper {
  padding-left: 0px;
}

.fixed-top {
  position: relative;
}
.form-control {
  font-size: 8px;
  height: 25px;
  min-width: 100px;
}
h5 {
  font-size: $max-size;
}
h6 {
  font-size: $medium-size;
}
.account-info {
  font-size: $medium-size;
}
.group-label-details,
.panel__title,
.acd-heading {
  font-size: $medium-size !important;
}
.nav-item:hover {
  z-index: 2;
}

.dropdown-menu {
  min-width: 120px;
}

.admin-header .dropdown .dropdown-menu {
  left: inherit;
}

.admin-header .dropdown-menu-group:focus,
.dropdown-menu-group:hover {
  .dropdown-menu {
    left: inherit;
    right: auto !important;
  }
}

.nav-main-screen {
  display: none !important;
}

.nav-mb-screen {
  display: block !important;
}

.flag-icon-item {
  width: 18px;
  height: 18px;
}

.title-language {
  font-size: $min-size;
}

.expanded-sidebar {
  width: 218px;
}

.side-menu-fixed .right-nav-text {
  visibility: visible !important;
}

.nav-main {
  .pull-left {
    display: flex;
  }
}

.icon-calendar {
  top: 12px;
}

.group-info-card.last-child {
  padding-right: 0px;
}

.group-info-card {
  padding-right: 0px;
}

.accordion-toggle-switcher {
  margin-top: 0px !important;
}

.acd-heading:before {
  top: 5px !important;
}

.modal-config .body-config {
  left: 0%;
  .title-form {
    font-size: $medium-size;
  }
}

.dnd-container {
  padding: 5px !important;
  width: 120px;
  min-height: 500px;
}

.Toastify__toast-container--top-center {
  left: 10% !important;
  width: 300px !important;
}

.modal-dialog.modal-custom.modal-70 {
  max-width: 90%;
}

.group-step-order {
  margin-right: 40px;
  margin-left: 30px;
  margin-top: 20px !important;
  margin-bottom: 40px;

  .label-step {
    font-size: 6px;
  }
}

.embrix-line-chart-type,
.embrix-chart-pie {
  width: 92%;
  margin-left: 0px !important;
}

.embrix-chart-pie .apexcharts-legend-text {
  font-size: 5px !important;
}

.embrix-chart-pie .apx-legend-position-right {
  width: 110px !important;
}

.number-chart-details {
  font-size: 4px;
  width: 30px;
  height: 30px;
  padding-top: 11px;
  margin-bottom: 5px;
}

.title-number {
  padding-top: 8px;
  margin-right: 30px;
}

.show-description {
  padding-right: 30px !important;
}
.apexcharts-theme-light {
  margin-left: -15px;
}

#embrix-btn-export {
  height: 30px;
}

.email-group {
  .form-control {
    height: auto !important;
  }
  .button {
    height: 25px;
    margin-top: 5px;
  }
}

.react-tagsinput-input {
  font-size: $medium-size !important;
}

.job-list-cards {
  padding-left: 0px;
}

.nav-main-item {
  visibility: visible !important;
  display: block !important;
}

td {
  .custom-react-select__control {
    min-width: 120px;
  }
}

.height-btn {
  height: 28px;
}

.group-btn-subscription {
  text-align: center;
}

.group-step-subscription {
  margin-right: 10px;
  margin-left: 40px;
  // margin-top: 20px !important;
  margin-bottom: 30px !important;

  .label-step {
    font-size: 6px;
  }
}

.form-collapse {
  .yellow,
  .blue {
    top: 12px;
    height: 18px;
    width: 18px;
  }
}

.mixed-chart {
  padding-right: 0px !important;
  overflow: auto;
  position: static !important;
}

.embrix-line-barchart-type {
  width: 90%;
  margin-left: 0px !important;
}

.chart-value {
  top: 25%;
  position: relative;
  z-index: 3;
  margin-left: 20px;
  display: flex;
}

.bar-chart-value {
  top: 20%;
  z-index: 3;
}

.apexcharts-legend-text {
  font-size: 5px !important;
}

.bar-title-number {
  padding-top: 35px;
  margin-right: 30px;
}

.traffic-dashboard {
  .mixed-chart {
    position: relative !important;
  }
}

.footer-bottom {
  padding-right: 30px;
}

.chart-item-dashboard {
  margin-right: 0px;
}

.embrix-yaxis-label {
  font-size: $min-size;
  padding-left: -10px;
}

.apexcharts-yaxis {
  z-index: 2;
}

.apexcharts-xaxis-label {
  font-size: $min-size;
}

.drag-drop-group {
  .title-form {
    font-size: 13px;
    font-weight: bold;
    padding: 5px;
    padding-top: 0px;
  }

  .item {
    font-size: 10px;
  }

  .label-item {
    min-width: 60px;
  }

  .dnd-group-comuns {
    min-width: 800px;
  }
}

.apexcharts-data-labels {
  display: none;
  rect {
    display: none;
  }
}

.bar-chart-value-min-max {
  top: 25%;
  right: 50px;
}

.bar-number-chart-details {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  padding-top: 12px;
  font-size: 10px;
}

.apx-legend-position-right {
  bottom: 10px !important;
  z-index: 22;
  top: auto !important;
  right: 0px !important;
}

.apexcharts-menu-icon {
  display: none;
}
