@import './styles/containers/customer-page.scss';
@import './styles/containers/tenant-page.scss';
@import './styles/containers/payment.scss';
@import './styles/containers/manage-profile.scss';
@import './styles/containers/jobs.scss';
@import './styles/containers/collection-page.scss';
@import './styles/containers/order-page.scss';
@import './styles/containers/base-configurations.scss';
@import './styles/containers/pricing-page.scss';
@import './styles/component/styles.scss';
@import '~react-step-progress-bar/styles.css';

$read-only-color: #545454;
$read-only-border-color: #efefef;

body.is-preload {
  font-family: 'Poppins', sans-serif;
  overflow-x: hidden;
}
body input.form-control {
  background: white;
  color: #323232;
  border: 1px solid #d6d6d6;
}
.modal-selector {
  .card-table-body {
    max-height: 50vh;
    overflow-y: scroll;
  }
}
.table-responsive.locking {
  overflow-y: visible;
  overflow-x: hidden;
}
.table-responsive td .inner-popover {
  position: static;
  display: flex;
  min-height: 28px;
  .react-select {
    display: block;
    height: 28px;
    margin-top: 3px;
    min-width: 150px;
    padding-left: 0px;
    &.select-focused {
      position: absolute;
    }
    .custom-react-select__menu {
      padding-left: 0px;
    }
  }
  .react-datepicker-wrapper {
    width: 87%;
  }
  .icon-calendar {
    position: relative;
    float: right;
    top: 5px;
    left: -30px;
  }
}
.table-responsive td .no-padding {
  padding-right: 0;
}
.select-group {
  .placeholder-selector {
    display: none;
  }
}
.tableFixHead {
  *,
  ::after,
  ::before {
    box-sizing: border-box;
    position: relative;
  }
  thead {
    background-color: white;
    z-index: 999;
    tr,
    th {
      z-index: 999;
      background: white;
    }
  }
  thead::after {
    z-index: -1;
  }
  thead tr::after {
    z-index: -1;
  }
  thead tr th::after {
    z-index: -1;
  }

  tbody {
    position: relative;
  }
  tbody tr,
  tbody td {
    position: relative;
  }
  .custom-control-label::after,
  .custom-control-label::before {
    position: absolute;
  }
}
.react-select {
  margin-top: 5px;
  &.custom-react-select--is-disabled {
    > div.custom-react-select__control {
      background-color: white !important;
      opacity: 1;
      color: $read-only-color !important;
      border: 1px solid $read-only-border-color;
    }
  }
  > div {
    border-radius: 2px;
    min-height: 20px;
    border: 1px solid #d6d6d6;
    background-color: white;
    padding-left: 10px;
  }
  .custom-react-select__indicator {
    padding: 4px;
  }
  &:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
    outline: none;
  }
  .custom-react-select__control {
    height: 28px;
    padding-left: 0;
  }
  .custom-react-select__control:focus,
  .custom-react-select__control--is-focused {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
  }
  .custom-react-select__control.custom-react-select__control--is-disabled.css-1hwalbh-control {
    background-color: #dde2e6;
    .css-107lb6w-singleValue {
      color: $read-only-color;
    }
  }
}
.form-group input[type='number'] {
  text-align: right;
  &::-webkit-inner-spin-button {
    margin-left: 5px;
  }
}
.form-group .react-datepicker-wrapper {
  display: block;
  margin-top: 5px;
  input {
    margin-top: 0px;
  }
  .react-datepicker__input-container {
    width: 100%;
    input:focus {
      border-color: #28a745;
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
    }
  }
  .react-datepicker__close-icon::after {
    background-color: transparent;
    color: #cccbcb;
    font-size: 23px;
    padding: 0px;
    padding-right: 25px;
    // padding-top: 5px;
    z-index: 2;
  }
}
.action-buttons {
  .button {
    margin-right: 5px;
    &:first-child {
      margin-right: 0px;
    }
  }
}
input.form-control::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  opacity: 0.7; /* Firefox */
}

textarea.form-control:disabled {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  background-color: white;
  opacity: 0.4 !important; /* Firefox */
  border-radius: 0;
}

input.form-control:disabled {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  background-color: white;
  opacity: 1; /* Firefox */
}

textarea.form-control:disabled {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  background-color: white;
  opacity: 1 !important; /* Firefox */
}

input.form-control:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  opacity: 0.7; /* Firefox */
}

input.form-control::-ms-input-placeholder {
  /* Microsoft Edge */
  opacity: 0.7; /* Firefox */
}
.content-wrapper {
  padding-bottom: 80px;
  padding-top: 0px !important;
}
.custom-control.custom-checkbox {
  cursor: pointer;
  label {
    cursor: pointer;
    &::before {
      background-color: white;
    }
    &::before,
    &::after {
      margin-left: -20px;
    }
    padding-top: 3px;
  }
}
nav.pagination {
  li.page-item {
    a.page-link {
      color: #fff;
      background-color: #6c757d;
      border-color: #6c757d;
      padding: 0.6rem 1rem;
      font-size: 14px;
      &:hover,
      &:active {
        color: #fff;
        background-color: #5a6268;
        border-color: #545b62;
      }
      &:focus {
        box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
      }
    }
    &.active {
      a.page-link {
        color: #fff;
        background-color: #5a6268;
        border-color: #545b62;
      }
    }
  }
}
div .form-control:focus {
  background: #ffffff;
  border: 0px;
  -webkit-box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  border-color: transparent;
}
.modal-dialog.modal-custom {
  &.modal-80 {
    max-width: 80%;
  }
  &.modal-70 {
    max-width: 70%;
  }
  &.modal-60 {
    max-width: 60%;
  }
}
tr.selected-row {
  // background-color: #28a745;
  // color: white;
}

table th.sortable {
  position: relative;
  cursor: pointer;
  &::before,
  &::after {
    content: '';
    border-width: 0 5px 5px;
    border-style: solid;
    border-color: #000 transparent;
    visibility: visible;
    right: 8px;
    top: 50%;
    position: absolute;
    opacity: 0.4;
    margin-top: -5px;
  }
  &::before {
    margin-top: 2px;
    border-bottom: none;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #000;
  }
  &.sorting {
    &::before,
    &::after {
      color: #28a745;
    }
    &::before {
      visibility: hidden;
    }
  }
  &.sorting-asc {
    &::after {
      border-top: none;
      border-bottom: 5px solid #28a745;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      visibility: visible;
      opacity: 1;
      margin-top: -2px;
    }
  }
  &.sorting-desc {
    &::after {
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      visibility: visible;
      opacity: 1;
      border-bottom: none;
      border-top: 5px solid #28a745;
      box-shadow: none;
      margin-top: -2px;
    }
  }

  .label-header {
    margin-right: 15px;
  }
}
.side-menu-fixed .side-menu li.active {
  a {
    background: rgba(0, 0, 0, 0.2);
    color: #ffffff;
  }
  .pull-right.pull-right-hover {
    display: block;
  }
}
.card.nested-section-card {
  overflow: auto;
  margin-top: 20px;
  .btn-rm-card {
    background: transparent;
    border: none;
    float: right;
  }
  > .card-header {
    border: 1px solid;
    border-color: #d6d6d6;
    border-radius: 10px;
    color: #3f3f3f;
    background-color: #ffffff;
    font-weight: 600;
  }
  > .card-body > .card > .card-header {
    // background-color: #96bd64;
    // background-color: #96bd64;
    // display: none;
  }
  > .card-body > .card > .card-body > .card {
    // box-shadow: none;
    > .card-header {
      // background-color: #cae2ad;
      background-color: transparent;
      // display: none;
    }
  }

  .btn-collapsible {
    i {
      float: right;
      background-color: #323232;
      color: #ffffff;
      font-size: 0.9rem;
      float: right;
      border-radius: 100%;
      padding: 3px 3px 2px 4px;
      // margin-right: 20px;
    }
  }

  .active-main {
    color: #28a745;
    border-color: #28a745;
    i {
      background-color: #28a745;
      color: #ffffff;
    }
  }
}
.sub-form {
  .btn-remove-sub-form {
    float: right;
    border: none;
    font-size: 25px;
    margin-top: -10px;
    background-color: transparent;
  }
}
.price-panel {
  .card-body .sub-form .card-header {
    display: none;
  }
}
i.is-required {
  color: #dc3545;
  position: absolute;
  font-size: 5px;
  margin: 1px 3px;
}
.label-header {
  position: relative;
}

.was-validated .form-group {
  .custom-react-select__control {
    border-color: #28a745;
    height: 28px;
  }
  &.has-error {
    .custom-react-select__control {
      border: 1px solid #dc3545;
    }

    .acd-heading {
      background: #dc3545 !important;
    }
  }
}
// .was-validated .custom-select:invalid, .was-validated .form-control:invalid {
//   border: 1px solid #dc3545;
// }
.btn-new-component.btn-danger {
  background: #dc3545;
  color: white;
}

// css btn disable
.button[disabled] {
  border: 1px solid #a3c773 !important;
  background-color: #cccccc !important;
  color: #666666 !important;
  // pointer-events: none;
  cursor: not-allowed;
}

.button[disabled]:hover {
  border: 1px solid #a3c773;
  background-color: #cccccc;
  color: #666666;
  cursor: not-allowed;
}
.navbar .nav-item.active span.button {
  border: 1px solid #28a745;
  background: #28a745;
  color: #ffffff;
}
.side-menu-fixed .side-menu {
  .pull-right-persist {
    opacity: 1;
  }
  .active {
    .icon-hover {
      opacity: 1;
    }
  }
}
.schedule-job-toggle-switcher {
  display: inline-block;
}
.accordion.plus-icon.round .acd-group {
  > .acd-des {
    display: none;
  }
  &.acd-active > .acd-des {
    display: block;
  }
  .accordion-toggle-switcher {
    float: right;
    margin-right: 28px;
    margin-top: 5px;
  }
  // > .acd-heading {
  //   background-color: #f6f7f8;
  // }
  > .acd-heading[disabled]:hover {
    background-color: #f6f7f8;
    color: #323232;
    opacity: 0.9;
  }
  > .acd-heading:before {
    content: '\f055' !important;
  }
  &.acd-active > .acd-heading:before {
    content: '\f056' !important;
  }
}
.was-validated {
  .acd-group {
    &.has-error {
      .acd-heading {
        background: #dc3545 !important;
        color: white;
      }
    }
  }
}

.page-size-box {
  width: auto;
  margin-right: 30px;
  margin-bottom: 65px;
  .page-size-select {
    span {
      display: inline-block;
      font-weight: 600;
    }
    .react-select {
      width: 60px;
      display: inline-block;
    }
  }
}

.pagination__item.page-item {
  display: none;
  &.active,
  &.prev-page,
  &.next-page {
    display: inline-block;
  }
}

#pre-loader {
  opacity: 1;
  transition: opacity 1s ease-out;
  -moz-transition: opacity 1s ease-out;
  -webkit-transition: opacity 1s ease-out;
  &.hide-loader {
    opacity: 0;
  }
}

.btn-bottom-right {
  padding: 10px 0px 30px 0px;
}

textarea {
  color: #323232 !important;
}

.react-datepicker-popper {
  z-index: 9999999 !important;
}

// css div disable
div[disabled] {
  background: transparent;
  // border: 1px solid #eeeeee;
  background-color: #cccccc !important;
  pointer-events: none;
}

div[disabled]:hover {
  border: 1px solid #a3c773;
  background-color: #cccccc;
  color: #666666;
}

.loader {
  opacity: 0.8 !important;
  .css-0 {
    margin-left: 10px;
  }
}

.btn-no-css {
  border: none;
  background: transparent;
}

.button-toggle-nav {
  font-size: 20px;
  cursor: pointer;
}

.no-padding {
  padding: 0px;
}

.remove-active-nav-bar {
  color: rgba(255, 255, 255, 0.5) !important;
  padding-left: 10px !important;
}

.title-language {
  color: #626262;
  margin-right: 5px;
  font-size: 1rem;
}

.flag-icon-item {
  width: 29px;
  height: 29px;
  margin-top: -5px;
}

.flag-icon {
  width: 29px;
  height: 29px;
  margin-right: 5px;
}

.img-logo {
  margin-top: 10px;
}

.title-language {
  color: #626262;
  margin-right: 5px;
  font-size: 1rem;
}

.dropdown-language {
  min-width: 150px;
}

.flag-icon-item {
  width: 29px;
  height: 29px;
  margin-top: -5px;
}

.item-tier {
  padding-bottom: 20px;
  border-bottom: 1px solid #666666 !important;
}

.container-fluid {
  padding-top: 0rem !important;
}

button {
  background-color: transparent;
}

.group-select-lang {
  // margin-bottom: 0px !important;
  margin: 10px 0px auto auto;
  .css-26l3qy-menu {
    padding-left: 0px;
    text-align: center;
  }
}

.info-form,
.read-only {
  input.form-control:disabled,
  .react-select.custom-react-select--is-disabled > div.custom-react-select__control {
    color: $read-only-color;
    opacity: 1 !important;
  }
}

.form-control[readonly] {
  background-color: white !important;
  opacity: 1 !important;
  border: 1px solid $read-only-border-color;
  color: $read-only-color;
  &:disabled {
    background-color: white;
    opacity: 1 !important;
  }
}

textarea.form-control:disabled {
  background-color: white;
  opacity: 1;
}

.read-only {
  .custom-react-select__control {
    color: $read-only-color;
    opacity: 1 !important;
    border: 1px solid $read-only-border-color;
  }
}

.prc-card {
  max-height: 100vh !important;
  overflow: auto;
}

.show-content {
  max-height: 800px;
  transition: max-height 0.3s;
  transition: max-height 0.3s;
  -moz-transition: max-height 0.3s;
  -webkit-transition: max-height 0.3s;
  -o-transition: max-height 0.3s;
}

.hidden-content {
  max-height: 0 !important;
  -moz-transition: max-height 0.3s;
  -ms-transition: max-height 0.3s;
  -o-transition: max-height 0.3s;
  -webkit-transition: max-height 0.3s;
  transition: max-height 0.3s;
  overflow: hidden;
  padding-bottom: 0px;
}

.child-item {
  .right-nav-text {
    padding-left: 10px;
  }
}

.cursor {
  cursor: pointer;
}

.title-fetch {
  font-weight: bold;
  padding-right: 10px;
  padding-top: -1px;
  font-size: 13px;
}

.btn-remove-po {
  margin-top: 30px;
}

.msg-reset-pass {
  font-size: 10px;
  // position: absolute;
  margin-top: 63px;
  text-align: center;
  background-color: #fafafa;
}

.filepond--credits {
  display: none;
}

.preview-group {
  text-align: center !important;
}

.react-multi-select {
  display: block;
  min-height: 28px !important;
  margin-top: 4px !important;
  min-width: 100% !important;
  padding-left: 0px;
  // &.select-focused {
  //   position: absolute;
  // }

  .custom-react-select__dropdown-indicator {
    padding: 6px !important;
  }
  .custom-react-select__control {
    // height: 30px;
    background: white;
    color: #000000 !important;
    border: 1px solid #d6d6d6;
    height: auto !important;
  }
  .custom-react-select__menu {
    padding-left: 0px;
  }
  .custom-react-select__control:focus,
  .custom-react-select__control--is-focused {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
  }
  .custom-react-select__control {
    background-color: white !important;
    opacity: 0.4;
  }
  .custom-react-select__control.custom-react-select__control--is-disabled.css-1hwalbh-control {
    background-color: #dde2e6;
    .css-107lb6w-singleValue {
      color: $read-only-color;
    }
  }
  .custom-react-select__value-container {
    // margin-top: -5px;
    // .custom-react-select__multi-value{}
    .custom-react-select__multi-value__label {
      color: #000000 !important;
    }
  }

  .custom-react-select__multi-value {
    border: 1px solid #d6d6d6;
  }

  .custom-react-select__clear-indicator {
    padding: 0px;
  }
}

.base64-logo {
  width: auto;
  max-height: 40px !important;
}

.login-logo {
  width: 80%;
  height: auto;
}

.grp-sumit-rvn {
  width: 80vw;
  padding: 20px;
  button {
    margin-top: 20px;
  }
}

.grp-sumit-rvn-viewer {
  width: 80vw;
  border-top: solid 2px #dee2e6;
  padding-top: 20px;
  // button {
  //   margin-top: 30px;
  // }
  .icon-calendar,
  .react-datepicker__close-icon {
    margin-top: 0px;
  }
}

.gpr-billable-srv {
  width: 70vw;
}

body > [class^='css-'] {
  z-index: 999999999999 !important;
}

.email-group {
  // display: flex;
  margin-right: 20px;
  justify-content: right;

  .email-form {
    width: auto;
    height: 30px;
    display: flex;
    .wr-input {
      min-width: 200px !important;
    }

    .label-item {
      margin-top: 10px;
    }
    .input-group {
      width: 90% !important;
    }
  }

  .react-tagsinput-input {
    margin: 0px;
    padding: 0px;
    min-width: 200px !important;
  }
}

.react-tagsinput-tag {
  background-color: #28a745 !important;
  color: white !important;
}

@media only screen and (max-width: 1485px) and (min-width: 1100px) {
  .grp-sumit-rvn {
    width: 75vw;
  }
  .grp-sumit-rvn-viewer {
    width: 75vw;
  }
}

.ico-toggle:hover {
  color: #28a745;
}

.dropdown-item:hover {
  color: #28a745;
}

// import css global
@import '~animate.css';
.sticky-parent-header {
  max-height: 650px !important;
  overflow: auto;
  table {
    width: 100%;
    border-collapse: collapse;
  }
  table thead th {
    position: -webkit-sticky;
    position: sticky;
    top: -1px;
    background-color: #fefefe;
  }

  /* here is the trick */
  table tbody:nth-of-type(1) tr:nth-of-type(1) td {
    border-top: none;
  }
  table thead .header-table {
    z-index: 3 !important;
    box-shadow: inset 0 1px 0 #dee2e6, inset 0 -1px 0 #dee2e6;
  }
}

.sticky-header {
  max-height: 550px !important;
  overflow: auto;
  table {
    width: 100%;
    // text-align: center;
    border-collapse: collapse;
  }
  // table tr th,
  // table tr td {
  //   border: 2px solid;
  // }
  table thead th {
    position: -webkit-sticky;
    position: sticky;
    top: -1px;
    background-color: #fefefe;
  }

  /* here is the trick */
  table tbody:nth-of-type(1) tr:nth-of-type(1) td {
    border-top: none;
  }
  table thead th {
    // border-bottom: 2px solid #dee2e6;
    // border-bottom: none !important;
    z-index: 2;
    box-shadow: inset 0 1px 0 #dee2e6, inset 0 -1px 0 #dee2e6;
    // padding: 2px 0;
  }
  table thead th {
    // background-clip: padding-box;
  }
}

.no-fixed-header {
  table thead th {
    z-index: 1;
    box-shadow: inset 0 1px 0 #dee2e6, inset 0 -1px 0 #dee2e6;
  }
}

.ico-question-embrix {
  font-size: 15px;
  cursor: pointer;
  margin-left: 10px;
}

.ico-question-embrix:hover {
  color: #28a745;
}

.xml-viewer {
  margin-top: 20px;
  background: white;
  border: 2px solid #dee2e6;
  padding: 10px;
}

.error-message {
  color: rgb(228, 1, 1);
  font-size: 13px;
}

.quick-note-order {
  font-size: 13px;
}

.title-order-note {
  font-weight: bold;
  text-decoration: underline;
}

.read-only-disable-field {
  input:disabled {
    color: black !important;
    opacity: 1;
  }
}

.sub-collapsible {
  .collapse__title {
    background: #f6f7f8 !important;
    color: #626262 !important;
    i {
      background-color: #323232 !important;
      float: right;
      border-radius: 100%;
      padding: 5px 6px;
      margin-top: 10px;
      margin-right: 20px;
      color: #ffffff !important;
    }
  }
  .active {
    .collapse__title {
      background: #28a745 !important;
    }
  }
}

.custom-checkbox {
  label::before {
    border-radius: 50% !important;
    width: 20px;
    height: 20px;
    margin-top: -5px;
  }
  label::after {
    border-radius: 50% !important;
    width: 20px;
    height: 20px;
    margin-top: -5px;
  }
  .custom-control-label {
    padding-left: 5px;
    padding-top: 0px !important;
  }
}

.csv-content {
  max-height: 600px;
  overflow: auto;
  font-size: 18px;
}

.group-create-account {
  border-top: 2px solid #28a745;
  padding-top: 10px;
  margin-top: 10px;
}

.btn-reload {
  border: none;
  font-size: 20px;
  float: right;
  margin-right: 10px;
  margin-bottom: 10px;
  -webkit-box-shadow: 4px 2px 15px 2px rgba(0, 0, 0, 0.27);
  box-shadow: 4px 2px 15px 2px rgba(0, 0, 0, 0.27);
  border-radius: 50%;
  color: white;
  background: #28a745;
}

.btn-reload:hover {
  background: black;
}

.check-item-account {
  margin-top: 30px;
}

.text-link {
  margin-left: 3px;
  color: #28a745;
  font-weight: bold;
}

.button-quick-note-billing {
  margin-top: 15px;
  margin-bottom: 20px;
}

.switch-billing {
  margin-top: 20px;
  margin-left: 10px;
}

.separation-border {
  border-top: 1px solid #626262;
}

.modal-item-right-title {
  position: absolute;
  right: 20px;
}

.warning-ico {
  color: #d5bd00;
  margin-right: 5px;
  font-size: 19px;
}

.header-act-info {
  padding-top: 0px !important;
  .nav-tabs {
    border: none;
  }
}

.account-info {
  font-size: 13px;
  padding-top: 10px;
  padding-bottom: 10px;
  .acct-info-label {
    font-weight: bold;
  }

  .comm-name-label {
    border-right: 1px solid #cbcbcb;
  }
}

.item-info-header {
  -webkit-box-shadow: 1px 2px 23px -3px rgba(0, 0, 0, 0.17);
  box-shadow: 1px 2px 23px -3px rgba(0, 0, 0, 0.17);
  border-radius: 0px 0px 10px 12px !important;
}

.header-modal {
  .modal-title {
    width: 100%;
  }
}

.header-group-tabel {
  top: 30px !important;
}

.embrix-one-line {
  .embrix-apexcharts-xcrosshairs {
    opacity: 1 !important;
    stroke: #7a7a7a !important;
    stroke-dasharray: 0% !important;
    stroke-width: 2px;
  }
}

.validity-pricing {
  margin-left: 100px;
}

.info-header-table-hover {
  font-size: 20px;
  margin-left: 5px;
  color: #7a7a7a;
  cursor: pointer;
}

.info-header-table-hover:hover {
  color: #28a745;
}

.tooltip-info-header-tabel,
.tooltip-details {
  width: 350px;
}

.title-pricing-details {
  font-size: 14px;
  margin-top: 2px;
}

.ti-flag-check-ico {
  font-size: 25px;
  color: #28a745;
}

.btn-reverse-payment,
.default-heigh-btn {
  height: 30px;
}

// .red-color {
//   color: #dc3545;
// }

.modal-selector {
  .item-info-header {
    margin-top: 22px;
  }
}

.text-success:disabled {
  color: #154d22 !important;
  opacity: 0.8;
  cursor: not-allowed;
}

.draggable {
  top: auto !important;
  left: auto !important;
}

.modal-config {
  max-width: 100% !important;
  // background: transparent !important;
  .modal-body {
    padding: 0px;
  }
  .column-config {
  }

  .modal-content {
    background: transparent;
    border: none;
  }

  .column-config {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  .body-config {
    left: 30%;
    position: relative;
    z-index: 2;
    padding: 20px;
    // padding-bottom: 60px;
    background: white;
    border-radius: 10px !important;

    .body-export-drd {
      max-height: 600px;
      overflow: auto;
      margin-left: 10px;
      margin-right: 10px;
      // padding-right: 10px;

      background: transparent;
      border-radius: 5px !important;
      -webkit-box-shadow: 1px 2px 23px -3px rgba(0, 0, 0, 0.17);
      box-shadow: 1px 2px 23px -3px rgba(0, 0, 0, 0.17);
      .item {
        -webkit-box-shadow: 1px 2px 23px -3px rgba(0, 0, 0, 0.17);
        box-shadow: 1px 2px 23px -3px rgba(0, 0, 0, 0.17);
        border-radius: 12px !important;
        border: 1px solid #28a745;
        font-weight: bold;
      }
    }

    .title-form {
      font-size: 20px;
    }

    .dnd-container {
      background: #e5e5e5;
      padding: 10px;
      -webkit-box-shadow: 1px 2px 23px -3px rgba(0, 0, 0, 0.17);
      box-shadow: 1px 2px 23px -3px rgba(0, 0, 0, 0.17);
      border-radius: 10px !important;
    }

    .drag-container {
      background: #aaaaaa;
      padding: 15px !important;
      -webkit-box-shadow: 1px 2px 23px -3px rgba(0, 0, 0, 0.17);
      box-shadow: 1px 2px 23px -3px rgba(0, 0, 0, 0.17);
      border-radius: 10px !important;
    }
    .drop-container {
      // -webkit-box-shadow: 1px 6px 15px 25px rgba(0, 0, 0, 0.83);
      // box-shadow: 1px 6px 15px 25px rgba(0, 0, 0, 0.83);
      // background: #efefef;
    }

    .item-drag {
      background: #263b4a;
      color: white;
      -webkit-box-shadow: 1px 6px 15px 25px rgba(0, 0, 0, 0.83);
      box-shadow: 1px 6px 15px 25px rgba(0, 0, 0, 0.83);
    }
    .item-drop {
      -webkit-box-shadow: 1px 6px 15px 25px rgba(0, 0, 0, 0.83);
      box-shadow: 1px 6px 15px 25px rgba(0, 0, 0, 0.83);
      background: #ffffff;
    }
    .item-drop:hover {
      background: #263b4a;
      color: white;
    }
  }
}

.separation-border-top {
  border-top: 2px solid #d3d3d3;
}

.btn-config {
  color: #28a745 !important;
  background: #ffffff;
  border-radius: 50% !important;
  height: 31px;
  width: 32px;
  padding: 3px 2px 0px 2px !important;
  -webkit-box-shadow: 4px 2px 15px 2px rgba(0, 0, 0, 0.27);
  box-shadow: 4px 2px 15px 2px rgba(0, 0, 0, 0.27);
  i {
    font-size: 18px;
    padding-top: 2px !important;
  }
}

.btn-config:hover {
  color: white !important;
}

// .button {
//   background: #ffffff;
//   padding: 10px 20px;
//   font-size: 1rem;
//   letter-spacing: 1px;
//   border: 0;
//   color: #28a745;
//   text-transform: uppercase;
//   font-weight: 500;
//   display: inline-block;
//   border-radius: 3px;
//   text-align: center;
//   border: 1px solid #28a745;
//   cursor: pointer;
//   -webkit-transition: all 0.5s ease;
//   -moz-transition: all 0.5s ease;
//   -ms-transition: all 0.5s ease;
//   -o-transition: all 0.5s ease;
//   transition: all 0.5s ease;
// }
// .button:hover {
//   background: #28a745;
//   color: #ffffff;
//   border-color: #eeeeee;
// }
// .button:focus {
//   background: #323232;
//   color: #ffffff;
//   border-color: #eeeeee;
// }

.btn-action-note {
  min-width: 150px;
}

.react-datepicker__year-dropdown--scrollable {
  text-align-last: center;
}

.text-link-upload,
.overlap-text-field {
  textarea,
  input {
    padding-right: 30px;
  }
}

.group-details-pattern {
  margin-top: 20px;
  border: 1px solid #d6d6d6;
  padding: 10px;
  margin-bottom: 20px;
}

.title-patern {
  font-size: 16px;
  font-weight: bold;
  padding-bottom: 20px;
}

.check-field {
  .custom-checkbox {
    margin-top: 10px;
  }
}

.input-with-right-label {
  display: flex;
  input {
    width: 60%;
  }
  .right-label {
    margin-left: 10px;
    white-space: nowrap;
    margin-top: 10px;
  }

  .left-label {
    white-space: nowrap;
    margin-top: 10px;
    margin-right: 10px;
  }
  .is-required {
    margin-top: 3px;
  }
}

.title-form-child {
  height: 30px;
  .button {
    height: 32px !important;
    margin-top: 15px;
    padding-top: 5px;
  }
}

.button.button-border {
  &.active {
    background: #28a745;
    color: #ffffff;
    border-color: #28a745;
  }
}

.btn-config-item {
  color: #28a745 !important;
  background: #ffffff;
  border-radius: 50% !important;
  height: 31px;
  width: 32px;
  padding: 1px 2px 0px 2px !important;
  -webkit-box-shadow: 4px 2px 15px 2px rgba(0, 0, 0, 0.27);
  box-shadow: 4px 2px 15px 2px rgba(0, 0, 0, 0.27);
  i {
    font-size: 18px;
    padding-top: 2px !important;
  }
}

.btn-config-item:hover {
  color: white !important;
}

.embrix-disabled-link {
  a[disabled] {
    pointer-events: none;
  }
}

.btn-reload-balances {
  border: none;
  font-size: 18px;
  margin-left: 10px;
  position: absolute;
  top: 16px;
  padding-top: 2px;
  -webkit-box-shadow: 4px 2px 15px 2px rgba(0, 0, 0, 0.27);
  box-shadow: 4px 2px 15px 2px rgba(0, 0, 0, 0.27);
  border-radius: 50%;
  color: white;
  background: #28a745;
}

.btn-reload-balances:hover {
  background: black;
}

.fa-trash {
  box-shadow: 1px 0px 14px -2px rgba(0, 0, 0, 0.54);
  -webkit-box-shadow: 1px 0px 14px -2px rgba(0, 0, 0, 0.54);
  -moz-box-shadow: 1px 0px 14px -2px rgba(0, 0, 0, 0.54);
  border-radius: 50%;
  padding: 3px 6px;
  border: 1px solid #28a745;
  font-size: 17px;
}

.fa-trash:hover {
  color: red;
}

.group-label-details {
  font-size: 13px;
  justify-content: center;
  margin-bottom: 30px;
  .label-color {
    width: 20px;
    height: 10px;
    margin-top: 5px;
  }

  .label-title {
    margin-right: 15px;
    margin-left: 5px;
  }
}

.group-pagination-selector {
  height: 70px;
}

.separation-border-lower {
  border-top: 1px solid #b1b1b1;
}

.collapsible {
  border: 1px solid;
  border-color: #c7c7c7;
  border-radius: 10px;
  color: #646464;
  background-color: #ffffff;

  .drag-icon {
    width: 30px;
    margin-top: 4px;
  }

  .control-group {
    width: calc(100% - 30px);
    cursor: pointer;
  }

  .btn-control {
    border: none;
    padding: 0px;
    border-radius: 100%;
    margin-top: 7px;
    i {
      background-color: #323232;
      border-radius: 100%;
      padding: 5px 6px;
      color: #ffffff;
    }
  }

  &:hover {
    color: #28a745;
    border-color: #28a745;
    i {
      background-color: #28a745;
      color: #ffffff;
    }
  }

  &.collapsible-full-size {
    // background-color: #28a745;
    color: #28a745;
    border-color: #28a745;
    i {
      background-color: #28a745;
      color: #ffffff;
    }
  }

  .panel__title {
    font-size: 1.143rem;
    font-weight: 500;
    display: inline-block;
    padding: 8px 8px;
  }
}

.service-table {
  margin-left: 10px;
  max-width: 90vw !important;
  padding-bottom: 0px;

  .line-table {
    max-width: 78vw !important;
    overflow: auto;
  }

  .fixed-width-table {
    width: 150px;
  }
}

.height-btn {
  height: 33px;
}

.height-pagination {
  height: 55px;
}

.accordion.gray .acd-heading {
  background-color: white !important;
  margin-bottom: 6px;
  padding: 7px 14px;
  border: 1px solid #d6d6d6;
  border-radius: 10px;
  color: #3f3f3f;
}

.accordion.gray .acd-group.acd-active .acd-heading {
  // box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
  // border-color: #28a745;
  border: 1px solid #28a745;
  border-radius: 10px;

  color: #28a745;
  background: rgb(255, 255, 255);
  // background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(21, 139, 0, 1) 100%);
}

.accordion.gray .acd-heading:hover {
  border: 1px solid #28a745;
  border-radius: 10px;
  color: #28a745;
  background: rgb(255, 255, 255);
}

.page-size-modal {
  .page-size-box {
    margin-bottom: 20px;
  }
}

.Toastify__toast-body {
  word-break: break-all;
}

.Toastify__toast-container {
  width: 400px !important;
}

.Toastify__toast-container--top-center {
  left: 48% !important;
}

.p-m {
  margin-top: -10px;
}

.dnd-container {
  padding: 4px;
  width: 250px;
  min-height: 500px;
}

.drd-profile {
  left: -300px;
}

@import './styles/responsive.scss';

.ico-info-tooltip {
  font-size: 15px;
  cursor: pointer;
}

.ico-info-tooltip:hover {
  color: #28a745;
}

nav.pagination-data {
  li.page-item-data {
    padding: 2px;
    a.page-link {
      // width: 20px;
      // color: #fff;
      // background-color: #6c757d;
      // border-color: #6c757d;
      border-radius: 50%;
      padding: 0.6rem 1rem;
      font-size: 14px;
      &:hover,
      &:active {
        color: #fff;
        background-color: #5a6268;
        border-color: #545b62;
      }
      &:focus {
        box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
      }
    }
    &.active {
      a.page-link {
        color: #fff;
        background-color: #5a6268;
        border-color: #545b62;
      }
    }
  }
}

.family-chart-group {
  background: white;
  height: 100%;
  font-size: 12px;
  -webkit-box-shadow: 4px 2px 15px 2px rgba(0, 0, 0, 0.27);
  box-shadow: 4px 2px 15px 2px rgba(0, 0, 0, 0.27);
  border-radius: 5px;
  margin-right: 5px;
  margin-left: 5px;

  .title {
    background: #28a745;
    color: white;
    font-weight: bold;
    text-align: center;
    border-radius: 5px 5px 0px 0px;
  }
  .description {
    // text-align: center;
    margin-top: 5px;
    margin-left: 5px;
  }
}

.family-group {
  width: 100%;
  height: 800px;
  margin-bottom: 100px;
  // background: white;
  .active {
    color: #28a745;
    .title {
      background: #28a745;
    }
  }
  .closed {
    color: #8c8c8c;
    .title {
      background: #8c8c8c;
    }
  }

  .inactive {
    color: orange;
    .title {
      background: orange;
    }
  }

  .title-description {
    margin-right: 5px;
    color: #323232;
    font-weight: bold;
  }
  .description-content {
    word-break: break-all;

    &.bold {
      font-weight: bold;
    }
  }
}

.middle-text-with-border {
  background-color: white;
  position: relative;
  top: -10px;
  padding: 0 10px;
  font-weight: bold;
  font-size: 15px;
  color: #28a745;
}

#embrix-btn-export,
.btn-send-email {
  height: 33px;
}

.rtable--flip tbody {
  td {
    min-height: 38.8px;
    border-top: 1px solid #dee2e6 !important;
  }

  // optional - enable iOS momentum scrolling
  -webkit-overflow-scrolling: touch;

  // scrolling shadows
  background: radial-gradient(left, ellipse, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 75%) 0 center,
    radial-gradient(right, ellipse, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 75%) 100% center;
  background-size: 10px 100%, 10px 100%;
  background-attachment: scroll, scroll;
  background-repeat: no-repeat;
}

// change these gradients from white to your background colour if it differs
// gradient on the first cells to hide the left shadow
.rtable--flip tbody tr:first-child {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0) 100%);
  background-repeat: no-repeat;
  background-size: 20px 100%;
}

.rtable--flip tbody tr:last-child {
  background-image: linear-gradient(to left, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0) 100%);
  background-repeat: no-repeat;
  background-position: 100% 0;
  background-size: 20px 100%;
}

.rtable--flip {
  display: flex;
  overflow: hidden;
  background: none;
}

.rtable--flip thead {
  display: flex;
  flex-shrink: 0;
  min-width: min-content;
}

.rtable--flip tbody {
  display: flex;
  position: relative;
  overflow-x: auto;
  overflow-y: hidden;
}

.rtable--flip tr {
  display: flex;
  flex-direction: column;
  min-width: min-content;
  flex-shrink: 0;
}

.rtable--flip td,
.rtable--flip th {
  display: block;
  min-height: 38.8px !important;
}

.rtable--flip td {
  background-image: none !important;
  // border-collapse is no longer active
  border-left: 0;
}

// border-collapse is no longer active
.rtable--flip th:not(:last-child),
.rtable--flip td:not(:last-child) {
  border-bottom: 0;
}

.ico-info {
  font-size: 15px;
  cursor: pointer;
}

.ico-info:hover {
  color: #28a745;
}

.rc-time-picker-input[disabled] {
  background-color: white !important;
  opacity: 1; /* Firefox */
  cursor: unset !important;
  color: #545454 !important;
}
