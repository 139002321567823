$color_border_dark: #dee5e7;
$sub_collapsible_color: #68a494;
@mixin clearfix() {
  .temp,
  &:before,
  &:after {
    content: ' ';
    display: table;
  }
  &:after {
    clear: both;
  }
}

@mixin collapsible-title {
  border: 1px solid;
  border-color: #c7c7c7;
  border-radius: 10px;
  color: #3f3f3f;
  background-color: #ffffff;
}

@mixin collapsible-title-active {
  color: #28a745;
  border-color: #28a745;
}

.input-group {
  margin-top: 5px;
  background-color: white;
  border: 1px solid #d6d6d6;
}

//css Date picker star //
.SingleDatePicker.SingleDatePicker_1 {
  padding-top: 5px;
  display: block;
}

input#date_input {
  padding: 0px 10px 0px;
  font-size: 12px;
  background: #f6f7f8;
  font-weight: normal;
}

.SingleDatePickerInput.SingleDatePickerInput_1.SingleDatePickerInput__withBorder.SingleDatePickerInput__withBorder_2 {
  width: 100%;
}

.DateInput.DateInput_1 {
  width: 100%;
}

.SingleDatePicker_picker__directionLeft_2 {
  top: 0px;
}
.display-none {
  display: none;
}
.invisiable {
  visibility: hidden !important;
}

// collapse start //

.form-collapse {
  margin-bottom: 0px !important;

  > .col-lg-12 {
    padding: 0;
  }
  .collapse__wrapper {
    &.boxed {
      border: none;
      .collapse__title {
        @include collapsible-title;
      }
      .collapse__title:focus {
        outline: none;
      }

      &.collapse__content {
        margin: 15px;
      }
      .form-container {
        padding: 0px !important;
      }
      .panel__heading {
        &.panel__heading--btn {
          .panel__title {
            font-size: 1.143rem;
            font-weight: 500;
            display: inline-block;
            padding: 8px 8px;
          }
        }
      }
    }
    &.active {
      .collapse__title {
        @include collapsible-title-active;
      }

      .panel__heading {
        &.panel__heading--btn {
          i {
            background-color: #28a745;
            color: #ffffff;
          }
        }
      }
    }
    .panel__heading {
      &.panel__heading--btn {
        i {
          background-color: #323232;
          float: right;
          border-radius: 100%;
          padding: 5px 6px;
          margin-top: 10px;
          margin-right: 20px;
          color: #ffffff;
        }
      }
    }
    .collapse__title:hover {
      cursor: pointer;
      @include collapsible-title-active;

      .panel__heading {
        &.panel__heading--btn {
          i {
            background-color: #28a745;
            color: #ffffff;
          }
        }
      }
    }
  }
  &:not(:last-child) {
    margin-bottom: 20px;
  }

  .yellow {
    background: yellow;
    height: 22px;
    width: 22px;
    position: absolute;
    right: 70px;
    top: 17px;
    border-radius: 50%;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
    -moz-box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
    border: 3px solid $color_border_dark;
  }

  .blue {
    background: #28a745;
    height: 22px;
    width: 22px;
    position: absolute;
    right: 70px;
    top: 17px;
    border-radius: 50%;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
    -moz-box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
    border: 3px solid $color_border_dark;
  }
}

// css sub collapse

.form-collapse {
  margin-bottom: 0px !important;

  > .col-lg-12 {
    padding: 0;
  }
  .collapse__wrapper {
    &.boxed {
      border: none;
      .collapse_sub_title {
        // background-color: #f6f7f8;
        // color: #626262;
        // padding: 0;
        // border-radius: 3px;
        @include collapsible-title;
      }
      .collapse_sub_title:focus {
        outline: none;
      }

      .collapse__content {
        // margin: 15px;
        // padding-top: 5px;
      }
      .form-container {
        padding: 0px !important;
      }
      .panel_sub_heading {
        &.panel_sub_heading--btn {
          .panel__title {
            font-size: 1.143rem;
            font-weight: 500;
            display: inline-block;
            padding: 8px 8px;
          }
        }
      }
    }
    &.active-sub-from {
      .collapse_sub_title {
        background-color: #28a745;
        color: #ffffff;
        border-color: #28a745;
      }

      .panel_sub_heading {
        &.panel_sub_heading--btn {
          i {
            background-color: #ffffff;
            color: #28a745;
          }
        }
      }
    }

    &.sub_collapse {
      &.active-sub-from {
        .collapse_sub_title {
          background-color: #ffffff;
          color: #28a745;
        }
        .panel_sub_heading {
          &.panel_sub_heading--btn {
            i {
              background-color: #28a745;
              color: #ffffff;
            }
          }
        }
      }

      &.non-active-sub-from {
        .collapse_sub_title {
          background-color: #ffffff;
          color: #626262;
        }
        .panel_sub_heading {
          &.panel_sub_heading--btn {
            i {
              background-color: #323232;
              float: right;
              border-radius: 100%;
              padding: 5px 6px;
              margin-top: 10px;
              margin-right: 20px;
              color: #ffffff;
            }
          }
        }
      }

      .collapse_sub_title:hover {
        cursor: pointer;
        // background-color: #28a745;
        color: #28a745;

        .panel_sub_heading {
          &.panel_sub_heading--btn {
            i {
              background-color: #28a745;
              color: #ffffff;
            }
          }
        }
      }
    }

    .panel_sub_heading {
      &.panel_sub_heading--btn {
        i {
          background-color: #323232;
          float: right;
          border-radius: 100%;
          padding: 5px 6px;
          margin-top: 10px;
          margin-right: 20px;
          color: #ffffff;
        }
      }
    }
    .collapse_sub_title:hover {
      cursor: pointer;
      background-color: #28a745;
      color: #ffffff;

      .panel_sub_heading {
        &.panel_sub_heading--btn {
          i {
            background-color: #ffffff;
            color: #28a745;
          }
        }
      }
    }
  }
  &:not(:last-child) {
    margin-bottom: 20px;
  }
}

///
.form-section {
  @include clearfix();
  ul {
    padding-left: 0;
    li {
      list-style-type: none;
    }
  }
  section {
    border: 1px solid $color_border_dark;
    margin: 10px;
    .table-title {
      position: relative;
      .form-section__icon-trash {
        position: absolute;
        top: 15px;
        right: 10px;
        cursor: pointer;
        i {
          font-size: 18px;
        }
        &.form-section__icon-trash--show {
          display: block;
        }
        &:focus {
          outline: none;
        }
      }
    }
    .form-content {
      padding: 20px;
      .form-content__center {
        width: 70%;
        margin: 0 auto;
      }
      .form__block {
        padding: 20px;
        border: 1px solid $color_border_dark;
        position: relative;
        margin-bottom: 10px;
        .form__half:first-child {
          margin-right: 30px;
        }
        button {
          position: absolute;
          top: 10px;
          right: 5px;
        }
      }
    }
    .form-content.body-modal-add-component,
    .form-content.body-modal-submit {
      border: 1px solid #b6d6b6;
      background-color: #e6f2e6;
    }
    .form-content.body-modal-cancel {
      border: 1px solid #d9b4b4;
      background-color: #f2e6e6;
    }
  }
  .form-section__btn-add {
    margin-left: 65px;
    &.form-section__btn-add-input {
      margin-left: 157px;
    }
  }
  .form-section__btn-next {
    float: right;
  }
}
.section-form-child {
  margin: 20px 0 0 0;
  .table-title {
    position: relative;
    padding: 15px;
    margin-bottom: 20px;
    .form-section__icon-trash {
      position: absolute;
      top: 5px;
      right: 5px;
      cursor: pointer;
    }
  }
  .form-content {
    padding: 0 20px 20px 20px;
  }
  &:first-child {
    margin: 0;
  }
}
.form-section {
  .section {
    position: relative;
    margin: 0;
    .button-trash {
      position: absolute;
      right: 10px;
      top: 10px;
      padding: 0 5px;
      i {
        color: #ff4861;
        font-size: 20px;
      }
      cursor: pointer;
      &:hover {
        background-color: darken(#ff4861, 10%);
        border-color: darken(#ff4861, 10%);
        i {
          color: darken(#fff, 20%);
        }
      }
    }
    .form-content {
      &.form-content--trash {
        margin-top: 20px;
      }
    }
    &.section-form-order {
      border: none;
      margin: 0;
      .button-trash {
        top: -43px;
        right: 0;
      }
    }
  }
}
// collapse end //

// group filter start //
.group-flex {
  display: flex;
  flex-wrap: wrap;

  .form-group {
    padding: 3px 5px;
  }

  // .form-control {
  //   margin-top: 5px;
  // }
}
// group filter end //

// btn Custom start //
.group-btn-custom {
  margin: auto 10px 3px;
  button {
    padding: 5px 10px;
    margin: 10px;
  }
  .btn-filter {
    float: right;
  }
  .btn-custom {
    margin: 10px;
  }
}
// btn Custom end //

.admin-header {
  .nav-item {
    margin: auto;
  }
}

//css footer
.footer {
  position: absolute;
  bottom: 0;
  padding-right: 30px;
}

// css side nav start//
.side-menu-fixed {
  overflow-y: auto;
  overflow-x: hidden;
}
.side-menu-fixed:hover {
  width: 230px !important;
}
.side-menu-fixed:hover .pull-right {
  visibility: visible !important;
}
.side-menu-fixed:hover .right-nav-text {
  visibility: visible !important;
}
.side-menu-fixed:hover .menu-title {
  display: block;
}
.pull-right-hover {
  display: none;
}
.navbar-nav li:hover > a > .pull-right-hover {
  display: block;
}

// css side nav end//

//page title right
.breadcrumb-item {
  padding-right: 10px;
}

.footer-bottom {
  position: absolute;
  bottom: 0;
  padding-right: 45px;
}

// css title tabel
.title-form {
  border-bottom: none;
  padding: 10px;
}

.table-form {
  padding: 0px 10px 10px 10px;
  margin-top: 0px !important;
  white-space: nowrap;
}

.form-focus {
  overflow-x: inherit !important;
}

.check-group {
  display: flex;
}

.check-item {
  margin-left: 10px;
}

.btn-custom {
  margin: 10px;
}

.form-with-item {
  margin-top: 20px;
}

.phone-group {
  display: flex;
}

.btn-first-phone {
  border: none;
  font-size: 22px;
  margin-top: 15px;
}
.btn-phone {
  border: none;
  font-size: 22px;
}

.invalid-checkbox {
  display: block;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}
.form-row {
  .form-control {
    margin-top: 5px;
    border-radius: 2px;
  }
}

.row_active {
  background-color: #28a745;
}

.text-order-group {
  font-size: 15px;
  margin: 5px 10px 0px 0px;
}

.modal-new-item {
  border-top: 1px solid #e9ecef;
  padding-top: 10px;
}

.btn-add-new {
  padding: 20px 0px 10px 20px !important;
  border-top: 1px solid #e9ecef;
}
.btn-one-payment {
  padding: 2px 7px !important;
  margin-top: 4px;
}

.btn-trash {
  border: none;
  font-size: 16px;
}

.expression-container {
  -webkit-box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.1);
  box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.1);

  .characters-expression {
    font-size: 13px;
  }
}

.placeholder-expression {
  margin: auto 0px;
  color: #b4b4b4;
}

.btn-no-border {
  border: none;
  font-size: 22px;
}

.btn-remove-expression {
  font-size: 10px;
  color: #dc3545;
}

.space-expression {
  margin-left: 10px;
  margin-right: 5px;
}

.item-expression {
  width: 155px;
}
.item-expression-process {
  width: 172px;
}

.item-container {
  cursor: move;
}

.last-item {
  width: 153px;
}

.note-content {
  font-size: 12px;

  div {
    margin: 8px 0px 8px auto;
  }
}

.note-content-stream {
  font-size: 12px;

  div {
    margin: 4px 0px 4px auto;
  }
}

.switch-non-active {
  .label-switch::before {
    content: 'N' !important;
    width: 23px !important;
    height: 23px !important;
    text-align: center;
    color: #dc3545;
    top: -2px !important;
    line-height: 22px;
    margin-left: -2px !important;
  }
}

.switch-active {
  .label-switch::before {
    content: 'Y' !important;
    width: 23px !important;
    height: 23px !important;
    text-align: center;
    color: #28a745;

    top: -2px !important;
    line-height: 22px;
    margin-left: -2px !important;
  }
}

.switch-active-green {
  .label-switch::before {
    content: 'Y' !important;
    width: 23px !important;
    height: 23px !important;
    text-align: center;
    color: #28a745 !important;

    top: -2px !important;
    line-height: 22px;
    margin-left: -2px !important;
  }
  .label-switch {
    background-color: #28a745 !important;
    border-color: #28a745 !important;
    box-shadow: #28a745 0px 0px 0px 8px inset !important;
    transition: border 0.4s ease 0s, box-shadow 0.4s ease 0s, background-color 1.2s ease 0s;
  }
}

.switch-stream {
  margin-top: 25px;
}

.sortable-handle-icon {
  font-size: 20px;
  cursor: pointer;
}

.processing-streams {
  // border: 1px solid #d6d6d6;
  padding: 10px 0px;
  -webkit-box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.1);
  box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.1);
}

.form-config-streams {
  padding: 5px 15px 5px 0px;
}

.btn-group-sort {
  margin-top: 10px;
  // border: 1px solid #d6d6d6;
  padding: 0px 27px 0px 0px;
}

.box-shadow {
  -webkit-box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.1);
  box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.1);
}

.ico-remove {
  font-size: 20px;
}

.no-border {
  border: none;
}

.icon-calendar {
  position: absolute;
  top: 22px;
  background: transparent;
  right: 12px;
  font-size: 17px;
}

.btn-with-ico {
  border: none;
  font-size: 22px;
}

.close-item {
  max-height: 0px;
  transition: max-height 0.15s ease-out;
  overflow: hidden;
}

.full-screen {
  max-height: 2000px;
  transition: max-height 0.15s ease-in;
}

.item-stream {
  width: 100%;
}
.no-border {
  border: none;
}

.css-87n7in-control {
  .ico-indicator {
    svg {
      -moz-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
      -moz-transition: all 0.2s linear;
      -webkit-transition: all 0.2s linear;
      transition: all 0.2s linear;
    }
  }
}

.custom-react-select__control--menu-is-open {
  .ico-indicator {
    svg {
      -moz-transform: rotate(180deg);
      -webkit-transform: rotate(180deg);
      transform: rotate(180deg);
      -moz-transition: all 0.2s linear;
      -webkit-transition: all 0.2s linear;
      transition: all 0.2s linear;
    }
  }
}

.last-item-config-dashboard {
  td {
    border-top: none;
  }
}

.no-border {
  border: none;
}

.ico-toggle {
  font-size: 21px;
}

.css-26l3qy-menu {
  z-index: 99999 !important;
}
.react-datepicker-popper {
  z-index: 99999999 !important;
}

th {
  input[type='checkbox'] {
    margin-right: 5px;
  }
}

.btn-add-phone-user {
  margin-top: 18px;
}

.no-data-chart-group {
  border: 1px dotted;
  .no-data-charts {
    position: absolute;
    top: 50%;
    left: 40%;
  }
}

// Handle Switch export

.switch-export-non-active {
  .label-switch::before {
    content: 'E' !important;
    width: 23px !important;
    height: 23px !important;
    text-align: center;
    color: #dc3545;
    top: -2px !important;
    line-height: 22px;
    margin-left: -2px !important;
  }
}

.switch-export-active {
  .label-switch::before {
    content: 'P' !important;
    width: 23px !important;
    height: 23px !important;
    text-align: center;
    color: #28a745;

    top: -2px !important;
    line-height: 22px;
    margin-left: -2px !important;
  }
}

.apexcharts-canvas {
  svg {
    // border: 1px solid #eeeeee;
  }
}

.mixed-chart {
  border: 2px solid #e2e2e2;
}

.no-data-chart {
  .apexcharts-graphical,
  .apexcharts-yaxis {
    display: none;
  }
  .apexcharts-text {
    font-style: italic;
  }
}

.icon-hover {
  position: absolute;
  right: 3px;
}

// Fix chart
.chart-group {
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
}

.chart-item-dashboard {
  width: 450px;
  margin-right: 20px;
}

.btn-delete {
  border: none;
  font-size: 22px;
}

.item-table-file-mapping {
  background: #f6f7f8;
  .fa {
    background-color: #323232 !important;
    color: #ffffff;
    border-radius: 50%;
    padding: 5px 6px;
    font-size: 0.8rem;
  }

  &.acitve {
    background: #28a745 !important;
    .fa {
      background-color: #ffffff !important;
      color: #28a745;
      border-radius: 50%;
      padding: 5px 6px;
      font-size: 0.8rem;
    }
  }
}

.item-table-file-mapping:hover {
  background: #28a745;
  // cursor: pointer;
  .fa {
    background-color: #ffffff !important;
    color: #28a745;
    border-radius: 50%;
    padding: 5px 6px;
    font-size: 0.8rem;
  }
}

.btn-expand-table {
  border: none;
}

.border-item {
  border: 1px solid #d6d6d6;
}
.btn-popup {
  font-size: 10px !important;
  white-space: nowrap;
  margin-left: 5px;
  margin-top: 5px;
  height: 28px;
}

.cursor {
  cursor: pointer !important;
  input {
    cursor: pointer !important;
  }
}

.table-collapsible {
  .content {
    background-color: #ffffff !important;
  }
  .apply-btn {
    background-color: #ffffff !important;
  }
  .apply-btn:hover {
    background-color: #ffffff !important;
    color: #28a745;
  }
  .apply-btn:focus {
    background-color: #ffffff !important;
    color: #28a745;
  }
  .content:hover {
    background-color: #ffffff !important;

    cursor: pointer !important;
    input {
      cursor: pointer !important;
    }
    .btn-expand-table {
      i:hover {
        background-color: #28a745 !important;
        color: #ffffff !important;
      }
    }
    .btn-expand-table:disabled:hover {
      i:hover {
        background-color: #323232 !important;
        color: #ffffff !important;
      }
    }

    .apply-btn {
      background-color: #ffffff !important;
    }
    .apply-btn:hover {
      background-color: #ffffff !important;
      color: #28a745;
    }
  }

  .btn-expand-table:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    i {
      background-color: #323232 !important;
      color: #ffffff !important;
    }
  }

  .btn-expand-table {
    .fa {
      background-color: #323232 !important;
      color: #ffffff;
      border-radius: 50%;
      padding: 5px 6px;
      font-size: 0.8rem;
    }
  }

  .btn-expand-table-disabled {
    display: none;
  }

  .fa-external-link {
    color: black;
  }
  .active-item {
    background-color: #ffffff !important;
    color: #ffffff;

    .btn-expand-table {
      .fa-minus {
        background-color: #28a745 !important;
        color: #ffffff !important;
      }
    }
  }
}

.table-collapsible-viewer {
  .content {
    // background-color: #f6f7f8 !important;
  }
  .content:hover {
    background-color: #28a745 !important;
    cursor: pointer !important;
    input {
      cursor: pointer !important;
    }
    .btn-expand-table {
      i {
        background-color: #ffffff !important;
        color: #28a745 !important;
      }
      .fa-external-link::before {
        content: '\f08e';
      }
    }
  }

  .btn-expand-table {
    .fa {
      background-color: #323232 !important;
      color: #ffffff;
      border-radius: 50%;
      padding: 5px 6px;
      font-size: 0.8rem;
    }
  }

  .active-item {
    background-color: #28a745 !important;
    color: #ffffff;

    .btn-expand-table {
      .fa {
        background-color: #ffffff !important;
        color: #28a745 !important;
      }
    }
  }
}

.chart-pie-dashboard {
  max-width: 450px;
  margin-right: 20px;
  max-height: 340px;
  margin-top: 8px;
}

.chart-pie {
  width: 600px;
  margin-right: 20px;
  height: auto;
  margin-top: 8px;
}

.chart-item {
  width: 600px;
  height: 500px;
}

.btn-step {
  background: #cecece;
  padding: 3px 10px;
  border-radius: 50%;
  color: white;
  font-weight: bold;
  &.active {
    background: #28a745;
    color: white;
  }
  &.disabled {
    background: #b5b5b5;
    color: white;
    cursor: not-allowed;
  }
}

.group-step {
  padding-right: 200px;
  padding-left: 200px;
  margin-top: 5px !important;
}

.RSPBprogression {
  // background: linear-gradient(to right, rgb(7 70 35), rgb(32 150 58)) !important;
  background: linear-gradient(to right, rgb(131, 145, 133), rgb(141, 155, 140)) !important;
  height: 2px;
}

.RSPBprogressBar {
  height: 1px;
}

.label-step {
  position: absolute;
  width: max-content;
  // top: 10px;
  margin-top: 5px;
  // left: -92%;
  font-size: 10px;
}

.label-type {
  position: absolute;
  font-weight: bold;
  left: 40px;
}

.apexcharts-tooltip {
  color: #28a745;
}

.detail-up-down-order {
  margin: auto;
  text-align: center;
  padding-bottom: 15px;
}

// .dragging {
//   .row-draging {
//     min-width: 100px;
//   }
// }

.custom-file-upload {
  width: 100%;
}

.input-upload {
  display: none;
}

.text-upload {
  margin: auto;
  text-align: center;
  padding: 100px;
}

.group-upload {
  padding: 10px;
  border: 2px dashed #dae1e9;
  cursor: pointer;
}

.text-uploading {
  color: #8b8b8b;
}

.embrix-bar-chart {
  padding-left: 15px;
}

.y-title {
  position: absolute;
  top: 46%;
  font-size: 15px;
  margin-left: 10px;
  transform: translateX(-50%) translateY(-50%) rotate(-90deg);
}

.x-title {
  font-size: 15px;
  text-align: center;
  padding-bottom: 10px;
}

.group-collapsible {
  .table-collapsible-viewer {
    .content {
      // background-color: #f6f7f8 !important;
    }
    .content:hover {
      background-color: #ffffff !important;
      cursor: auto !important;
      input {
        cursor: pointer !important;
      }
      .btn-expand-table {
        i {
          background-color: #28a745 !important;
          color: #ffffff !important;
        }
      }

      .btn-expand-table:hover {
        i {
          background-color: #28a745 !important;
          color: #ffffff !important;
        }
      }
    }

    .btn-expand-table:hover {
      i {
        background-color: #28a745 !important;
        color: #ffffff !important;
      }
    }

    .btn-expand-table {
      .fa {
        background-color: #323232 !important;
        color: #ffffff;
        border-radius: 50%;
        padding: 5px 6px;
        font-size: 0.8rem;
      }
    }

    .active-item {
      background-color: #ffffff !important;
      color: #000000;

      .btn-expand-table {
        .fa {
          background-color: #28a745 !important;
          color: #ffffff !important;
        }
      }
    }
  }
}

.embrix-chart-type {
  width: 60%;
  height: auto;
  .mixed-chart {
    box-shadow: -1px 4px 51px -6px rgba(0, 0, 0, 0.65);
    -webkit-box-shadow: -1px 4px 51px -6px rgba(0, 0, 0, 0.65);
    -moz-box-shadow: -1px 4px 51px -6px rgba(0, 0, 0, 0.65);
    border-radius: 10px;
    border: none;
    padding-right: 70px;
  }
}

.embrix-line-chart-type {
  width: 70%;
  height: auto;
  .mixed-chart {
    position: relative;
    box-shadow: 18px 12px 16px 0 rgba(0, 0, 0, 0.25), -8px -8px 12px 0 rgba(255, 255, 255, 0.3);
    -webkit-box-shadow: 18px 12px 16px 0 rgba(0, 0, 0, 0.25), -8px -8px 12px 0 rgba(255, 255, 255, 0.3);
    -moz-box-shadow: 18px 12px 16px 0 rgba(0, 0, 0, 0.25), -8px -8px 12px 0 rgba(255, 255, 255, 0.3);
    border-radius: 10px;
    // background: linear-gradient(135deg, rgba(0, 0, 0, 0.22), rgba(255, 255, 255, 0.25));
    background: linear-gradient(135deg, rgba(126, 126, 126, 0.22), rgba(255, 255, 255, 0.25));
    border: none;
    padding-right: 10px;
  }
  .show-description {
    padding-right: 130px;
  }
}

// .line-chart-overlap {
//   margin-right: 120px;
// }

.arrow_box {
  box-shadow: -1px 4px 51px -6px rgba(0, 0, 0, 0.65);
  -webkit-box-shadow: -1px 4px 51px -6px rgba(0, 0, 0, 0.65);
  -moz-box-shadow: -1px 4px 51px -6px rgba(0, 0, 0, 0.65);
  background-color: #28a745;
  color: white;
}

.apexcharts-tooltip {
  background-color: #28a745;
  color: white;
}

.embrix-chart-pie {
  width: 60%;
  box-shadow: 18px 12px 16px 0 rgba(0, 0, 0, 0.25), -8px -8px 12px 0 rgba(255, 255, 255, 0.3);
  border-radius: 10px;
  background: linear-gradient(135deg, rgba(126, 126, 126, 0.22), rgba(255, 255, 255, 0.25));
  border-radius: 10px;
  border: none;
  padding: 30px 0px;

  .apexcharts-legend-marker {
    height: 20px !important;
    width: 20px !important;
    box-shadow: 18px 12px 16px 0 rgba(0, 0, 0, 0.25), -8px -8px 12px 0 rgba(255, 255, 255, 0.3);
    -webkit-box-shadow: 18px 12px 16px 0 rgba(0, 0, 0, 0.25), -8px -8px 12px 0 rgba(255, 255, 255, 0.3);
    -moz-box-shadow: 18px 12px 16px 0 rgba(0, 0, 0, 0.25), -8px -8px 12px 0 rgba(255, 255, 255, 0.3);
  }

  .apexcharts-legend-text {
    font-size: 16px !important;
    top: -5px;
  }

  .apx-legend-position-right {
    width: 280px;
  }

  .apexcharts-menu-icon {
    margin-top: -50px;
    margin-right: 50px;
  }
}

.chart-value {
  position: absolute;
  right: 10px;
  top: 20%;
}

.number-chart-details {
  box-shadow: 18px 12px 16px 0 rgba(0, 0, 0, 0.25), -8px -8px 12px 0 rgba(255, 255, 255, 0.3);
  width: 80px;
  height: 80px;
  border-radius: 50%;
  text-align: center;
  padding-top: 32px;
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 12px;
}

.max-number {
  color: rgb(68, 1, 1);
  background: linear-gradient(-45deg, rgba(8, 53, 3, 0.22), rgba(123, 255, 79, 0.25));
}

.min-number {
  color: rgb(255, 255, 255);
  background: linear-gradient(-45deg, rgba(151, 0, 0, 0.911), rgba(148, 0, 0, 0.25));
}

.avg-number {
  color: rgb(0, 0, 0);
  background: linear-gradient(-45deg, rgba(2, 2, 2, 0.22), rgba(255, 255, 255, 0.25));
}

.title-number {
  padding-top: 32px;
  margin-left: 5px;
  font-weight: bold;
}

.chart-item-dashboard {
  .mixed-chart {
    position: relative;
    box-shadow: 18px 12px 16px 0 rgba(0, 0, 0, 0.25), -8px -8px 12px 0 rgba(255, 255, 255, 0.3);
    -webkit-box-shadow: 18px 12px 16px 0 rgba(0, 0, 0, 0.25), -8px -8px 12px 0 rgba(255, 255, 255, 0.3);
    -moz-box-shadow: 18px 12px 16px 0 rgba(0, 0, 0, 0.25), -8px -8px 12px 0 rgba(255, 255, 255, 0.3);
    border-radius: 10px;
    background: linear-gradient(135deg, rgba(126, 126, 126, 0.22), rgba(255, 255, 255, 0.25));
    border: none;
    padding-right: 20px;
  }
}

.chart-pie-dashboard {
  position: relative;
  box-shadow: 18px 12px 16px 0 rgba(0, 0, 0, 0.25), -8px -8px 12px 0 rgba(255, 255, 255, 0.3);
  -webkit-box-shadow: 18px 12px 16px 0 rgba(0, 0, 0, 0.25), -8px -8px 12px 0 rgba(255, 255, 255, 0.3);
  -moz-box-shadow: 18px 12px 16px 0 rgba(0, 0, 0, 0.25), -8px -8px 12px 0 rgba(255, 255, 255, 0.3);
  border-radius: 10px;
  background: linear-gradient(135deg, rgba(126, 126, 126, 0.22), rgba(255, 255, 255, 0.25));
  border: none;
  padding: 30px 0px;
}

.apexcharts-menu {
  width: 115px;
}
.switch-active {
  input:checked + span {
    background-color: #28a745 !important;
    border-color: #28a745 !important;
    box-shadow: #28a745 0px 0px 0px 8px inset !important;
  }
}

.number-form {
  .form-control-number {
    padding: 6px 30px 6px 30px;
  }
  .btn-control {
    font-size: 10px;
    width: 18px;
    height: 18px;
    margin-top: 10px;
    z-index: 1;
    cursor: pointer;
    -webkit-box-shadow: 0px 2px 8px -1px rgba(0, 0, 0, 0.23);
    -moz-box-shadow: 0px 2px 8px -1px rgba(0, 0, 0, 0.23);
    box-shadow: 0px 2px 8px -1px rgba(0, 0, 0, 0.23);
    border-radius: 50%;
    border: 1px solid #bababa;
  }
  button:hover {
    color: #28a745 !important;
  }
  button:disabled {
    color: #000000 !important;
    cursor: not-allowed;
  }
  i {
    margin-left: -3px;
    padding-top: -2px !important;
  }
  .minus-number {
    margin-right: -22px;
    margin-left: 5px;
  }

  .plus-number {
    margin-left: -22px;
  }
}

@-moz-document url-prefix() {
  .number-form {
    i {
      margin-left: -1px;
      padding-top: 10px;
    }
    button {
      margin-top: 12px;
    }
  }
}

.embrix-line-barchart-type {
  width: 70%;
  height: auto;
  .mixed-chart {
    position: relative;
    box-shadow: 18px 12px 16px 0 rgba(0, 0, 0, 0.25), -8px -8px 12px 0 rgba(255, 255, 255, 0.3);
    -webkit-box-shadow: 18px 12px 16px 0 rgba(0, 0, 0, 0.25), -8px -8px 12px 0 rgba(255, 255, 255, 0.3);
    -moz-box-shadow: 18px 12px 16px 0 rgba(0, 0, 0, 0.25), -8px -8px 12px 0 rgba(255, 255, 255, 0.3);
    border-radius: 10px;
    background: linear-gradient(135deg, rgba(203, 203, 203, 0.22), rgba(255, 255, 255, 0.25));
    border: none;
    padding-right: 10px;
  }
  .show-description {
    padding-right: 130px;
  }
}

.mixed-chart {
  .label-details {
    width: 15px;
    height: 15px;
    // border-radius: 10px;
    text-align: center;
    margin-top: 33px;
    margin-bottom: 10px;
    font-weight: bold;
    font-size: 12px;
  }
}

.chart-item-grouped {
  width: 600px;
  min-height: auto;
}

.mixed-chart {
  border: 2px solid #e2e2e2;
}

.box-shadow-btn {
  -webkit-box-shadow: 0 1px 7px 0 rgba(0, 0, 0, 0.14);
  -moz-box-shadow: 0 1px 7px 0 rgba(0, 0, 0, 0.14);
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.14);
}

.swith-with-label {
  span {
    width: 55px !important;
  }
  &.switch-non-active {
    .label-switch::before {
      width: 33px !important;
      height: 30px !important;
      top: -5px !important;
      content: attr(deactive-attr) !important;
      font-size: 7px;
      line-height: 28px;
    }
  }

  &.switch-active {
    .label-switch::before {
      width: 33px !important;
      height: 30px !important;
      top: -5px !important;
      content: attr(active-attr) !important;
      font-size: 6px;
      line-height: 28px;
    }
  }
}

// .new-order-provisioning {
//   .collapse__title {
//     background-color: $sub_collapsible_color !important;
//   }
// }

.support-sub-table {
  .content:hover {
    background-color: $sub_collapsible_color !important;
    color: #ffffff;
    .btn-expand-table {
      i {
        background-color: #ffffff !important;
        color: $sub_collapsible_color !important;
      }
    }
  }

  .active-item {
    background-color: $sub_collapsible_color !important;
    color: #ffffff;

    .btn-expand-table {
      .fa {
        background-color: #ffffff !important;
        color: $sub_collapsible_color !important;
      }
    }
  }
}

.group-step-order {
  // padding-right: 200px;
  // margin-left: 200px;
  // margin-right: 70px;
  margin-top: 5px !important;
  margin-left: auto;
  margin-right: 20px;
  // margin: auto;
}

.group-step-subscription {
  margin-left: auto;
  margin-right: 80px;
}

.group-btn-subscription {
  margin-top: 10px;
}

.embrix-card-collapsible {
  padding: 5px 8px;
  // background: transparent;
  background-color: #fff;
}

.drag-drop-group {
  overflow: auto;

  .dnd-group-comuns {
    min-width: 1200px;
  }
  .body-columns {
    min-width: 24%;
  }
  .dnd-table-container {
    min-width: 100%;
    min-height: 200px;
  }

  .title-form {
    font-size: 17px;
    font-weight: bold;
    padding: 5px;
    padding-top: 0px;
  }

  .body-columns {
    background: #f3f2f2;
    padding: 10px;
    box-shadow: 2px -1px 12px -4px rgba(0, 0, 0, 0.43);
    -webkit-box-shadow: 2px -1px 12px -4px rgba(0, 0, 0, 0.43);
    -moz-box-shadow: 2px -1px 12px -4px rgba(0, 0, 0, 0.43);
    border-radius: 10px !important;
    margin-right: 10px;
    margin-left: 10px;
  }

  .body-export-drd {
    overflow-y: auto;
    max-height: 800px;
    width: 100%;
  }

  .item {
    background: white;
    // border-radius: 0px 10px 10px 0px;
    box-shadow: 1px 2px 23px -3px rgba(0, 0, 0, 0.17);
    font-size: 14px;
    border-left: 3px solid;
    border-color: #28a745;
  }

  .item:hover {
    background: #f9f9f9;
  }

  .group-item {
    margin-bottom: 7px;
  }

  .label-item {
    font-weight: bold;
    margin-right: 10px;
    min-width: 90px;
  }
  .content-item {
    float: right;
    .button {
      margin-bottom: 10px;
      padding: 2px;
      margin-top: -5px;
    }
  }

  .dnd-container {
    padding: 10px;
    -webkit-box-shadow: 1px 2px 23px -3px rgba(0, 0, 0, 0.17);
    box-shadow: 1px 2px 23px -3px rgba(0, 0, 0, 0.17);
    border-radius: 10px !important;
  }

  .drag-container {
    background: #d8d7d7;
    padding: 15px !important;
    -webkit-box-shadow: 1px 2px 23px -3px rgba(0, 0, 0, 0.17);
    box-shadow: 1px 2px 23px -3px rgba(0, 0, 0, 0.17);
    overflow: hidden;
    border-radius: 10px !important;
  }
}

.sticky-thc {
  overflow: auto !important;
}

.bar-chart-value-min-max {
  position: absolute;
  right: 10px;
  top: 45%;
}

.bar-number-chart-details {
  box-shadow: 18px 12px 16px 0 rgba(0, 0, 0, 0.25), -8px -8px 12px 0 rgba(255, 255, 255, 0.3);
  width: 45px;
  height: 45px;
  border-radius: 50%;
  text-align: center;
  padding-top: 15px;
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 12px;
}

.bar-title-number-min-max {
  padding-top: 15px;
  margin-left: 5px;
  font-weight: bold;
}
